<div class="menu-left">
  <nav class="top-4">
    <div class="pb-8 space-y-1 overflow-x-hidden">
      <button (click)="burgerMenu()" class="text-black hover:text-gray-500 group flex items-center px-3 py-2 text-md font-medium">
        <svg class="text-black group-hover:text-gray-500 flex-shrink-0 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
      <a [routerLinkActive]="['text-black']" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/guard']" class="text-black hover:text-gray-500 group flex items-center px-3 py-2 text-md font-medium">
        <svg class="text-black group-hover:text-gray-500 flex-shrink-0 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
        </svg>
        <span class="truncate menu-left-expanded ml-3"> Página inicial </span>
      </a>
      <a [routerLinkActive]="['text-black']" href="{{environment.services.bot}}/guard/application/bot" class="text-black hover:text-gray-500 group flex items-center px-3 py-2 text-md font-medium">
        <svg class="group-hover:text-gray-500 flex-shrink-0 h-4 w-4 stroke-white stroke-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" aria-hidden="true">
          <path fill="#000000" d="M320 0c17.7 0 32 14.3 32 32l0 64 120 0c39.8 0 72 32.2 72 72l0 272c0 39.8-32.2 72-72 72l-304 0c-39.8 0-72-32.2-72-72l0-272c0-39.8 32.2-72 72-72l120 0 0-64c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16l32 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-32 0zM264 256a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zm152 40a40 40 0 1 0 0-80 40 40 0 1 0 0 80zM48 224l16 0 0 192-16 0c-26.5 0-48-21.5-48-48l0-96c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48l0 96c0 26.5-21.5 48-48 48l-16 0 0-192 16 0z" />
        </svg>
        <span class="truncate menu-left-expanded ml-3"> Robô </span>
      </a>
    </div>

    <div class="pt-10">
      <div class="mt-3 space-y-1">
        <a pTooltip="API" [routerLinkActive]="['text-gray-600']" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/guard/application/integration/api']" class="text-black hover:text-gray-500 group flex items-center px-3 py-2 text-md font-medium">
          <svg class="text-black group-hover:text-gray-500 flex-shrink-0 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
          <span class="truncate menu-left-expanded ml-3"> API </span>
        </a>
      </div>
    </div>
  </nav>
</div>
