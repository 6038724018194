<ng-container *ngIf="profile?.id">
  <div class="main-container flex flex-col px-4">
    <div class="relative flex justify-between pt-4">
      <div class="flex lg:static xl:col-span-2 mx-1">
        <div class="flex-shrink-0 flex items-center">
          <a [routerLink]="['/guard']">
            <img class="block w-[60px]" src="{{environment.app.logo}}" alt="{{environment.app.name}}" />
          </a>
        </div>
      </div>
      <div class="flex items-center justify-end col-span-4 mx-4 space-x-3 text-black">
        <div class="flex flex-col">
          <span class="text-sm font-medium"> {{profile.name}} </span>
          <small class="text-xxs">
            <a [routerLink]="['/guard/customer']"> {{'@'}}{{profile.customer.id}} </a>
          </small>
        </div>

        <ng-container *ngIf="profile.url_avatar === null">
          <a [routerLink]="['/guard/customer']">
            <span class="inline-block h-10 w-10 flex-none rounded-full overflow-hidden bg-gray-100">
              <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                <path fill="#4f46e5" d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
          </a>
        </ng-container>

        <ng-container *ngIf="profile.url_avatar !== null">
          <a [routerLink]="['/guard/customer']">
            <img src="{{profile.url_avatar}}" alt="{{profile.name}}" class="h-10 w-10 flex-none rounded-full object-cover" />
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
